<template>
  <div>
    <div class="text-center">
      <div v-if="loading == true" class="d-block">
        <b-spinner
          no-wrap
          class="p-0"
          type="grow"
          variant="primary"
        ></b-spinner>
        <b-spinner class="p-2" type="grow" variant="primary"></b-spinner>
        <b-spinner class="p-0" type="grow" variant="primary"></b-spinner>
      </div>
    </div>

    <section id="dashboard-ecommerce" v-if="!loading">
      <!-- default -->
      <div>
        <b-row v-if="this.$store.state.userData.type == 'Both'"  class="mb-1 text-right">
          <b-col>
             <b-button
             @click="ChangeType('Services')"
      class="mr-1"
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
    >
      Bookings
    </b-button>
     <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
      @click="ChangeType('Products')"
    >
      Orders
    </b-button>
          </b-col>
        </b-row>

        <b-row>
          <b-col lg="4">
            <b-row class="match-height">
              <!-- Bar Chart - Orders -->
              <b-col lg="6" md="3" cols="6">
                <ecommerce-order-chart :data="totalOrders" />
              </b-col>
              <!--/ Bar Chart - Orders -->
              <!-- line Chart - Sales -->
              <b-col lg="6" md="3" cols="6">
                <ecommerce-profit-chart :data="totalSales" />
              </b-col>
              <!--/ line Chart - Sales -->
              <!-- Earning chart -->
              <b-col lg="6" md="3" cols="6">
               
          <statistic-card-vertical
                v-if="this.$store.state.userData.typeC == 'Services'"
          icon="GridIcon"
          :statistic="totalProducts"
          statistic-title="Services"
          color="primary"
        />
        <statistic-card-vertical
                v-else
          icon="GridIcon"
          :statistic="totalProducts"
          statistic-title="Products"
          color="primary"
        />
              </b-col>
                 <b-col lg="6" md="3" cols="6">
                <statistic-card-vertical
          icon="PackageIcon"
          :statistic="ppackage.service"
          statistic-title="Package"
          color="info"
        />
              </b-col>
              <!--/ Earning chart -->
            </b-row>
          </b-col>
          <!-- order stats Card -->
          <b-col lg="4">
            <orderStatistics :data="data" />
          </b-col>
          <!--/  order stats Card -->
          <!-- Orders Card -->
          <b-col lg="4">
            <dashboardOrders :data="latestOrders" />
          </b-col>
          <!--/ Orders Card -->
        </b-row>
        <b-row class="match-height">
          <b-col lg="12">
            <chartjs-bar-chart />
          </b-col>
        </b-row>
      </div>
    </section>
  </div>
</template>

<script>
import { BRow, BCol, BSpinner, BOverlay, BButton, BBadge } from 'bootstrap-vue';
import { getUserData } from '@/auth/utils';
//import EcommerceBrowserStates from './EcommerceBrowserStates.vue'
//import EcommerceTransactions from './EcommerceTransactions.vue'
import EcommerceEarningsChart from './EcommerceEarningsChart.vue';
import EcommerceProfitChart from './EcommerceProfitChart.vue';
import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue'
import EcommerceOrderChart from './EcommerceOrderChart.vue';
import EcommerceCompanyTable from './EcommerceCompanyTable.vue';
import DashboardOrders from './DashboardOrders.vue';
import OrderStatistics from './OrderStatistics.vue';
import NewCustomers from './NewCustomers.vue';
import ChartjsBarChart from './ChartjsBarChart.vue';
import chartjsData from './chartjsData.js';
import flatPickr from 'vue-flatpickr-component';

export default {
  components: {
    BRow,
    BCol,
    StatisticCardVertical,
    EcommerceOrderChart,
    ChartjsBarChart,
    BButton,
    //BBadge,
    //  EcommerceBrowserStates,
    //EcommerceEarningsChart,
    EcommerceProfitChart,
    EcommerceOrderChart,
    //EcommerceCompanyTable,
    DashboardOrders,
    OrderStatistics,
    //NewCustomers,
    //flatPickr,
    BSpinner,
    //BOverlay
  },
  data() {
    return {
      latestOrders:[],
      chartjsData,
      totalOrders:'',
      totalSales:'',
      totalProducts:'',
      ppackage:{},
      date: '',
      loading: false,
      data: {},
    };
  },
  created() {
    this.chartLoading = true;
    this.loading = true;
    var myObj = {
   
      id: this.$store.state.userData.userID,
      type: this.$store.state.userData.typeC,
    };
    console.log(myObj);
    var axios = require('axios');

    var config = {
      method: 'get',
      url: 'https://brandaway.fastech.pk/api/Dashboard/GetDashboard/'+ this.$store.state.userData.typeC + '/' + this.$store.state.userData.userID ,
      headers: {
        Authorization:
          'bearer ' + this.$store.state.userData.token ,
      },
    };

    axios(config)
      .then((response) => {
        // console.log(JSON.stringify(response.data));

        //  this.data  =  response.data;
        console.log(response.data);
        if (response.data.status === 'success') {
          this.data = response.data.goal;
          console.log('-----Asas------',response.data.latestOrders);
           let myarr = [];
          
          myarr.push(this.data.percentage);
           this.data.percentage = myarr;

          //console.log('myper', this.data.goal.percentage);
      this.totalOrders = response.data.totalOrders;
      this.latestOrders = response.data.latestOrders;
      this.totalSales = response.data.totalSales;
      this.totalProducts = response.data.totalProducts;
      this.ppackage = response.data.package;

          var max = 0;
          max = Math.max.apply(null, response.data.sales);
          console.log(max);
          this.chartjsData.latestBarChart.options.scales.yAxes[0].ticks.max = 0;
          this.chartjsData.latestBarChart.options.scales.yAxes[0].ticks.max =
            max;

          console.log(
            'MyMaximum',
            this.chartjsData.latestBarChart.options.scales.yAxes[0].ticks.max,
          );
          //  response.data.sales.forEach(item => {
          //     this.maxVal = Math.max.apply(Math, item);
          //     this.minVal = Math.min.apply(Math, item);
          //  });
          // console.log(this.maxVal,this.minVal);
          //this.data = response.data;
          this.chartjsData.latestBarChart.data.labels = [];
          this.chartjsData.latestBarChart.data.labels = response.data.mydays;
          this.chartjsData.latestBarChart.data.datasets[0].data = [];
          this.chartjsData.latestBarChart.data.datasets[0].data =
            response.data.sales;
          //console.log( "Null",this.chartjsData.latestBarChart.data.datasets[0].data);
          this.chartLoading = false;
          this.loading = false;
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    // data
    // this.$http.get('/ecommerce/data')
    //   .then(response => {
    //     this.data = response.data

    //     // ? Your API will return name of logged in user or you might just directly get name of logged in user
    //     // ? This is just for demo purpose
    //     const userData = getUserData()
    //     this.data.congratulations.name = userData.fullName.split(' ')[0] || userData.username
    //   })
    // var axios = require('axios');

    // var config = {
    //   method: 'get',
    //   url:
    //     'https://okaaik.fastech.pk/api/dashboard/TotalOrders/' +
    //     this.$store.state.userData.userID +
    //     '/?fromdate=0001-01-01 &todate= 0001-01-01',
    //   headers: {
    //     Authorization:
    //       'bearer 56EeZXfkyt2E4yYawlxCo3FFMZiFmhDs2VZbA9jLIfTs70L_z5gC9yAMCchwnNRUcicotXJNc3GcSB8M-T9i8PLd-HUMk4KXom3Uu_kC4kbFhSk02Z9YQRwZqVdXjECBVCA0gOhnVoRLztCFQxNgVJbeWEMubqzh8dFpvnkIoC_2FF0kyCzKbwj4SYtF1fMMyhy2CO8DOIWUTZtdpwpI-IjGB0CyHhav_I_ag72apOUtKKhAoshLdYfcFz3QBYC0Pj-FIyEbzz_S5Rcp9fiIG3y5UM6RtSf8zP08M42rVjkLHwOb5cc8YafdfFExhGFI',
    //   },
    // };

    // axios(config)
    //   .then((response) => {
    //     // console.log(JSON.stringify(response.data));

    //     //  this.data  =  response.data;
    //     //console.log( response.data);
    //     if (response.data.status === 'success') {
    //       this.data = response.data;
    //       let myarr = [];
    //       myarr.push(this.data.goal.percentage);

    //       this.data.goal.percentage = myarr;
    //       console.log('myper', this.data.goal.percentage);
    //       console.log(this.data);
    //       this.requiresActions = response.data.requiresActions;
    //       this.readyForDelivery = response.data.readyForDelivery;
    //       this.underProgress = response.data.underProgress;
    //       var configpro = {
    //         method: 'get',
    //         url:
    //           'https://okaaik.fastech.pk/api/dashboard/productsAndCustomers/' +
    //           this.$store.state.userData.userID +
    //           '/?fromdate=0001-01-01 &todate=0001-01-01',
    //         headers: {
    //           Authorization:
    //             'bearer 56EeZXfkyt2E4yYawlxCo3FFMZiFmhDs2VZbA9jLIfTs70L_z5gC9yAMCchwnNRUcicotXJNc3GcSB8M-T9i8PLd-HUMk4KXom3Uu_kC4kbFhSk02Z9YQRwZqVdXjECBVCA0gOhnVoRLztCFQxNgVJbeWEMubqzh8dFpvnkIoC_2FF0kyCzKbwj4SYtF1fMMyhy2CO8DOIWUTZtdpwpI-IjGB0CyHhav_I_ag72apOUtKKhAoshLdYfcFz3QBYC0Pj-FIyEbzz_S5Rcp9fiIG3y5UM6RtSf8zP08M42rVjkLHwOb5cc8YafdfFExhGFI',
    //         },
    //       };

    //       axios(configpro)
    //         .then((response) => {
    //           // console.log(JSON.stringify(response.data));

    //           //  this.data  =  response.data;
    //           //console.log( response.data);
    //           if (response.data.status === 'success') {
    //             // let mostsoldproducts  = [];
    //             // response.data
    //             this.productandCustomerData = response.data;
    //             console.log(this.productandCustomerData);
    //             this.loading = false;
    //           }

    //           //   this.myProducts = response.data.data;
    //           //    console.log(this.myProducts);
    //           // if(this.myProducts.data.length == 0)
    //           //  {
    //           //    this.productEmpty = true;
    //           //  }
    //         })
    //         .catch(function (error) {
    //           console.log(error);
    //         });
    //     }

    //     //   this.myProducts = response.data.data;
    //     //    console.log(this.myProducts);
    //     // if(this.myProducts.data.length == 0)
    //     //  {
    //     //    this.productEmpty = true;
    //     //  }
    //   })
    //   .catch(function (error) {
    //     console.log(error);
    //   });
  },
  methods: {
    ChangeType(type)
    {
      this.$store.state.userData.typeC = type;
      this.LoadData();
    },
    LoadData() {
   this.chartLoading = true;
    this.loading = true;
    var myObj = {
   
      id: this.$store.state.userData.userID,
      type: this.$store.state.userData.typeC,
    };
    console.log(myObj);
    var axios = require('axios');

    var config = {
      method: 'get',
      url: 'https://brandaway.fastech.pk/api/Dashboard/GetDashboard/'+ this.$store.state.userData.typeC + '/' + this.$store.state.userData.userID ,
      headers: {
        Authorization:
          'bearer ' + this.$store.state.userData.token ,
      },
    };

    axios(config)
      .then((response) => {
        // console.log(JSON.stringify(response.data));

        //  this.data  =  response.data;
        console.log(response.data);
        if (response.data.status === 'success') {
          this.data = response.data.goal;
          console.log('-----Asas------',response.data.latestOrders);
           let myarr = [];
          
          myarr.push(this.data.percentage);
           this.data.percentage = myarr;

          //console.log('myper', this.data.goal.percentage);
      this.totalOrders = response.data.totalOrders;
      this.latestOrders = response.data.latestOrders;
      this.totalSales = response.data.totalSales;
      this.totalProducts = response.data.totalProducts;
      this.ppackage = response.data.package;

          var max = 0;
          max = Math.max.apply(null, response.data.sales);
          console.log(max);
          this.chartjsData.latestBarChart.options.scales.yAxes[0].ticks.max = 0;
          this.chartjsData.latestBarChart.options.scales.yAxes[0].ticks.max =
            max;

          console.log(
            'MyMaximum',
            this.chartjsData.latestBarChart.options.scales.yAxes[0].ticks.max,
          );
          //  response.data.sales.forEach(item => {
          //     this.maxVal = Math.max.apply(Math, item);
          //     this.minVal = Math.min.apply(Math, item);
          //  });
          // console.log(this.maxVal,this.minVal);
          //this.data = response.data;
          this.chartjsData.latestBarChart.data.labels = [];
          this.chartjsData.latestBarChart.data.labels = response.data.mydays;
          this.chartjsData.latestBarChart.data.datasets[0].data = [];
          this.chartjsData.latestBarChart.data.datasets[0].data =
            response.data.sales;
          //console.log( "Null",this.chartjsData.latestBarChart.data.datasets[0].data);
          this.chartLoading = false;
          this.loading = false;
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
